import { BaseService } from './base.service'

export class AgentGouvenementService extends BaseService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super()
    }

    // eslint-disable-next-line no-unused-vars
    createAgentGouvenement(data) {
        // console.log('data: ', data);
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post('admins/register', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }


    findAgentGouvenement(data) {
        let params = {}
        if (data && data.options) {
            params.options = data.options
        }
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get('/inscriptions/list', {
                    params,
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    getAgentGouvenement(id) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get(`/inscriptions/${id}`, {})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    updateAgentGouvenement({ id, data }) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .put(`/inscriptions/${id}`, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}

export default new AgentGouvenementService()