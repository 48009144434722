import agentGouvenementService from '@/services/http/agent-gouvenement.service.js'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async createAgentGouvenement({ rootState }, data) {
            const res = await agentGouvenementService.createAgentGouvenement(data)
            return res
        },
        async findAgentGouvenement({ rootState }, data) {
            const res = await agentGouvenementService.findAgentGouvenement(data)
            return res
        },

        async getListTypesEtablissement({ rootState }, data) {
            const res = await etablissementsService.getListTypesEtablissement(data)
            return res
        },
        async getEtablissement({ rootState }, data) {
            const res = await etablissementsService.getEtablissement(data)
            return res
        },
        async findEtablissement({ rootState }, data) {
            const res = await etablissementsService.findEtablissement(data)
            return res
        },

        async verifyEtablissementEmail({ rootState }, data) {
            const res = await etablissementsService.verifyEtablissementEmail(data)
            return res
        },
        async validateInscriptionEtablissement({ rootState }, data) {
            const res = await etablissementsService.validateInscriptionEtablissement(data)
            return res
        },
        async rejectInscriptionEtablissement({ rootState }, data) {
            const res = await etablissementsService.rejectInscriptionEtablissement(data)
            return res
        },
        async updateEtablissement({ rootState }, data) {
            const res = await etablissementsService.updateEtablissement(data)
            return res
        },
        async createAgent({ rootState }, data) {
            const res = await etablissementsService.createAgent(data)
            return res
        },
        async getAgent({ rootState }, data) {
            const res = await etablissementsService.getAgent(data)
            return res
        },
        async archiverAgent({ rootState }, data) {
            const res = await etablissementsService.archiverAgent(data)
            return res
        },
    },
}