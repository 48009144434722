<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex flex-wrap justify-content-between">
        <h1 class="my-auto">
          Liste des agents MTCA
        </h1>
        <b-button
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({ name: 'agents-create' })"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter un agent MTCA</span>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <ListAgentsMtcaComponent />
    </b-card>
    <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <!-- <div class="d-flex flex-row">  -->
        <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListAgentsMtcaComponent from './component/ListAgentsMtcaComponent.vue'

export default {
  name: 'ArticleList',
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    ListAgentsMtcaComponent,
    ToastificationContent,
  },
  data() {
    return {
      tableData: [],
      filters: { options: {} },
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
    }
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    uploadData(action) {
      this.loadingExport = true
      // if( action === 'allData'){
      //   this.query.filter.type = []
      //   this.query.filter.text = []
      // }
      const params = {}
      /* if (action === 'filteredData') {
        const filters = buildFiltersForRequest(this.filtersForm)
        console.log('filters: ', filters)
        params = {
          filter: filters,
        }
      } */
      console.log('params: ', params)

      this.action_exportEtablissements({ params: { ...params } })
        .then(async res => {
          if (res.status === 200) {
            this.loadingExport = false
            try {
              const blob = res.data
              const newBlob = new Blob([blob])
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob)
                const link = document.createElement('a')
                link.href = objUrl
                link.download = 'inscriptions.csv'
                link.click()
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl)
                }, 250)
              }
            } catch (error) {
              console.log('error: ', error)
            }
          }
        })
        .catch(err => {
          this.loadingExport = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.pagres {
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
</style>
